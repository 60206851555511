import { Search, Notifications, Language, Menu } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./navbar.scss";
import mecoLogoMain from "../../images/mecoLogoMain.png";
import LanguageSelector from "../languageselector/LanguageSelector";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  window.onscroll = () => {
    setIsScrolled(window.scrollY === 0 ? false : true);
    return () => (window.onscroll = null);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
      <div className="container">
        <div className="mobile-menu-icon" onClick={toggleMenu}>
          <Menu />
        </div>
        <div className={isMenuOpen ? "menu open" : "menu"}>
          <div className="left">
            <img src={mecoLogoMain} alt="" />
          </div>
          <div className="right">
            <div className="profile">
              <Language className="iconglobe" />
              <div className="options language-selector-options">
                <LanguageSelector />
              </div>
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="mobile-menu">
            <span>
              <a
                href="https://www.meconv.be/nl"
                target="_blank"
                rel="noreferrer"
              >
                {t("nav_home")}
              </a>
            </span>
            <span>
              <a
                href="https://www.meconv.be/nl/over-ons/leveringen"
                target="_blank"
                rel="noreferrer"
              >
                {t("nav_favorites")}
              </a>
            </span>
            <span>
              <a
                href="https://www.meconv.be/nl/over-ons"
                target="_blank"
                rel="noreferrer"
              >
                {t("nav_about")}
              </a>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
