import React from "react";
import "./home.scss";
import Navbar from "../../components/navbar/Navbar";
import Form from "../../components/form/Form";
import Footer from "../../components/footer/Footer";

export default function Home() {
  return (
    <div className="home">
      <Navbar />
      <Form />
      <Footer />
    </div>
  );
}
