import React from "react";
import mecoLogoMain from "../../images/mecoLogoMain.png";
import "./footer.scss";

function Footer() {
  return (
    <div>
      <div className="footer"></div>
      <div className="meco-logo">
        <img src={mecoLogoMain} alt="Logo Meco Group" />
      </div>
    </div>
  );
}

export default Footer;
