import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import Collapsible from "react-collapsible";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../images/Header.png";
import bannerDutch from "../../images/bannerDutch.svg";
import bannerFrench from "../../images/bannerFrench.svg";
import rightsDutch from "../../images/rightsDutch.svg";
import rightsFrench from "../../images/rightsFrench.svg";
import FormSuccess from "../formsuccess/FormSuccess";
import "./form.scss";

export default function Form() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, formState } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      bedrijf: "",
      klantnummer: "",
      permissions: [],
      billingMail: "",
      reminderMail: "",
      digitalReceiptMail: "",
      termsAndConditions: "",
      newsletter: "",
    },
  });

  const addExtension = (event) => {
    event.preventDefault();
    setExtensionCount(extensionCount + 1);
  };

  const permissions = [
    {
      label: t("form_main_user"),
      value: "main_user",
      secondLabel: t("form_main_explanation"),
    },
    {
      label: t("form_user"),
      value: "user",
      secondLabel: t("form_user_explanation"),
    },
    {
      label: t("form_limited_user"),
      value: "limited_user",
      secondLabel: t("form_limited_explanation"),
    },
  ];

  const [showAdditionalUsers, setShowAdditionalUsers] = useState(false);
  const [extensionCount, setExtensionCount] = useState(0);
  const [authToken, setAuthToken] = useState("");
  const [tokenFromMail, setTokenFromMail] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [formData, setFormData] = useState(null);
  const [extensionDuplicateEmailErrors, setExtensionDuplicateEmailErrors] =
    useState([]);
  const [topLevelEmailError, setTopLevelEmailError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [extensionServerErrorMessages, setExtensionServerErrorMessages] =
    useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://onboard.meconv.be/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client_id: process.env.REACT_APP_CLIENT_ID,
            password: process.env.REACT_APP_PASSWORD,
          }),
        });

        const data = await response.json();
        console.log("API response:", data);

        if (data.token) {
          setAuthToken(data.token);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    /// Extract language and token from URL
    const urlParts = window.location.href.split("/");
    const extractedLanguage = urlParts[3];
    const extractedToken = urlParts[urlParts.length - 1];

    // Save language and token in localStorage
    if (extractedToken) {
      console.log("extractedToken", extractedToken);
      setTokenFromMail(extractedToken);
      if (extractedLanguage === "nl") {
        i18n.changeLanguage("nl");
      } else {
        i18n.changeLanguage("fr");
      }
    }
    fetchData();
  }, []);

  const saveFormDataAsJson = () => {
    if (formData) {
      const jsonData = JSON.stringify(formData, null, 2);
      const blob = new Blob([jsonData], { type: "application/json" });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "form_data.json";
      //link.click();

      // Clean up the URL and the link element
      URL.revokeObjectURL(url);
      link.remove();
    }
  };

  const fetchDataWithToken = useCallback(
    async (token) => {
      if (authToken) {
        try {
          const response = await fetch(
            `https://onboard.meconv.be/api/customer/${token}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          const data = await response.json();
          console.log("Fetched data with token:", data);

          setValue("firstName", data.firstname);
          setValue("lastName", data.name);
          setValue("email", data.email);
          setValue("bedrijf", data.company_name);
          setValue("klantnummer", data.customer_id);
        } catch (error) {
          console.error("Data fetch error:", error);
          navigate("/not-found");
        }
      } else {
        console.warn("Token from mail not found");
      }
    },
    [authToken, navigate]
  );

  useEffect(() => {
    fetchDataWithToken(tokenFromMail); // Call fetchDataWithToken with tokenFromMail
  }, [tokenFromMail, fetchDataWithToken]); // Add fetchDataWithToken to the dependency array

  useEffect(() => {
    if (showAdditionalUsers) {
      setExtensionCount(1);
    } else {
      setExtensionCount(0);
    }
  }, [showAdditionalUsers]);

  const hasDuplicateEmails = (data) => {
    const topLevelEmail = data.email;
    const extensionEmails = Array.from(
      { length: extensionCount },
      (_, i) => data.extension[i]?.email || ""
    );

    const allEmails = [topLevelEmail, ...extensionEmails];
    const uniqueEmails = new Set(allEmails);

    console.log("uniquemails", uniqueEmails);
    console.log("formdata", data);

    return uniqueEmails.size !== allEmails.length;
  };

  const getStoredLanguage = () => {
    const storedLanguage = localStorage.getItem("i18nextLng");
    return storedLanguage ? storedLanguage : "nl";
  };

  const onSubmit = async (data) => {
    // Update the state of form data
    setFormData(data);

    // Checking for Duplicate Email for Top-Level Email Field and Extension Email Fields
    const hasDuplicates = hasDuplicateEmails(data);

    setTopLevelEmailError(hasDuplicates);
    setExtensionDuplicateEmailErrors(
      Array.from({ length: extensionCount }, () => hasDuplicates)
    );

    // Collapsible Approach - Checking for Duplicate Email for Extension Email Fields
    const extensionErrors = Array.from(
      { length: extensionCount },
      () => hasDuplicates
    );
    setExtensionDuplicateEmailErrors(extensionErrors);

    data.language = getStoredLanguage();

    if (!hasDuplicates) {
      try {
        setSubmissionStatus(null); // Reset submission status

        const response = await axios.post(
          `https://onboard.meconv.be/api/customer/${tokenFromMail}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        console.log("duplicateServerMail", response.data.duplicates);

        if (response.data.success) {
          setSubmissionStatus("success");
          console.log("Form submitted successfully!");

          // Clear the responseData.error
          setServerErrorMessage("");

          // Save the form data as a JSON file
          saveFormDataAsJson();
        } else if (response.data.error === "duplicate emails") {
          console.error("Form submission failed due to duplicate emails.");

          // Check if the submitted email matches any of the duplicates
          const duplicateEmails = response.data.duplicates;
          const submittedEmail = data.email;

          // Update extension-specific error messages
          const extensionErrors = Array.from(
            { length: extensionCount },
            (_, index) =>
              duplicateEmails.includes(data.extension[index]?.email)
                ? t("form_server_duplicate")
                : ""
          );
          setExtensionServerErrorMessages(extensionErrors);

          // Update the top-level error message
          const hasSubmittedEmailDuplicate =
            duplicateEmails.includes(submittedEmail);
          if (hasSubmittedEmailDuplicate) {
            setServerErrorMessage(t("form_server_duplicate"));
          } else {
            setServerErrorMessage("");
          }
        } else {
          setSubmissionStatus("error");
          console.error("Form submission failed.");
        }
      } catch (error) {
        setSubmissionStatus("error");
        console.error("Form submission error:", error);
      }
    } else {
      console.log("Form has duplicate email errors.");
    }
  };

  return (
    <div className="form-wrapper">
      {submissionStatus === "success" ? (
        <FormSuccess />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <img
            src={i18n.language === "nl" ? bannerDutch : bannerFrench}
            alt="banner title"
            className="custom-banner"
          />
          <img src={Header} alt="banner shop" className="custom-banner"></img>
          <div className="text-header">
            <strong>{t("form_paragraph1")}</strong>
            <br />
          </div>
          <div className="text-body">
            {t("form_paragraph2")}
            <br />
          </div>
          <div className="text-header">
            <strong>{t("form_paragraph3")}</strong>
            <br />
          </div>
          <div className="text-body">{t("form_paragraph4")}</div>
          <div className="text-body">{t("form_paragraph5")}</div>
          <div className="text-body">{t("form_paragraph6")}</div>
          <div className="text-body">{t("form_paragraph7")}</div>
          <div className="text-body">{t("form_paragraph8")}</div>
          <div className="text-body">{t("form_paragraph9")}</div>
          <div className="form-section">
            <h3 className="label">{t("form_credentials")}</h3>
            <label className="label">{t("form_firstname")}</label>
            <input
              className="textbox"
              {...register("firstName", { required: true })}
              placeholder={t("form_firstname")}
            />
            {formState.errors.firstName && (
              <p className="error-message">{t("form_field_required")}</p>
            )}

            <label className="label">{t("form_name")}:</label>
            <input
              className="textbox"
              {...register("lastName", { required: true, minLength: 2 })}
              placeholder={t("form_name")}
            />
            {formState.errors.lastName && (
              <p className="error-message">{t("form_field_required")}</p>
            )}

            <label className="label">Email:</label>
            <input
              className="textbox"
              {...register("email", {
                required: true,
                minLength: 2,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
              })}
              placeholder="Email"
            />
            {serverErrorMessage && (
              <p className="error-message">{serverErrorMessage}</p>
            )}
            {topLevelEmailError && (
              <p className="error-message">
                {t("form_duplicate_email_warning")}
              </p>
            )}
            {formState.errors.email && (
              <p className="error-message">{t("form_field_required")}</p>
            )}

            <label className="label">{t("form_company")}:</label>
            <input
              disabled={true}
              className="textbox"
              {...register("bedrijf", { minLength: 2 })}
              placeholder={t("form_company")}
            />

            <label className="label">{t("form_customer_number")}</label>
            <input
              disabled={true}
              className="textbox"
              {...register("klantnummer", { minLength: 2 })}
              placeholder={t("form_customer_number")}
            />

            <div className="collapsible-trigger">
              <label>
                <input
                  type="radio"
                  value="off"
                  checked={!showAdditionalUsers}
                  onChange={() => {
                    setShowAdditionalUsers(false);
                    setExtensionCount(0);
                  }}
                />
                {t("form_sole_user")}
              </label>
              <label>
                <input
                  type="radio"
                  value="on"
                  checked={showAdditionalUsers}
                  onChange={() => setShowAdditionalUsers(true)}
                />
                {t("form_more_users")}
              </label>
            </div>
          </div>
          {showAdditionalUsers && (
            <Collapsible
              className="collapseField"
              open={showAdditionalUsers}
              trigger={[]}
            >
              <div>
                {[...Array(extensionCount)].map((_, index) => (
                  <div key={index} className="form-section">
                    <h3 className="label">
                      {t("form_user")} {index + 1}
                    </h3>
                    <label className="label">{t("form_firstname")}</label>
                    <input
                      className="textbox"
                      {...register(`extension[${index}].firstName`, {
                        required: t("form_field_required"),
                      })}
                      placeholder={t("form_firstname")}
                    />
                    {formState.errors.extension &&
                      formState.errors.extension[index] &&
                      formState.errors.extension[index].firstName && (
                        <p className="error-message">
                          {formState.errors.extension[index].firstName.message}
                        </p>
                      )}
                    <label className="label">{t("form_name")}</label>
                    <input
                      className="textbox"
                      {...register(`extension[${index}].lastName`, {
                        required: t("form_field_required"),
                      })}
                      placeholder={t("form_name")}
                    />
                    {formState.errors.extension &&
                      formState.errors.extension[index] &&
                      formState.errors.extension[index].lastName && (
                        <p className="error-message">
                          {formState.errors.extension[index].lastName.message}
                        </p>
                      )}
                    <label className="label">Email:</label>
                    <input
                      className="textbox"
                      {...register(`extension[${index}].email`, {
                        required: t("form_field_required"),
                      })}
                      placeholder="Email"
                    />
                    {extensionServerErrorMessages[index] && (
                      <p className="error-message">
                        {extensionServerErrorMessages[index]}
                      </p>
                    )}
                    {extensionDuplicateEmailErrors[index] && (
                      <p className="error-message">
                        {t("form_duplicate_email_warning")}
                      </p>
                    )}
                    {formState.errors.extension &&
                      formState.errors.extension[index] &&
                      formState.errors.extension[index].email && (
                        <p className="error-message">
                          {formState.errors.extension[index].email.message}
                        </p>
                      )}
                    <h3 className="label">{t("form_permissions")}</h3>
                    <div className="permissions-section">
                      {permissions.map((permission, permissionIndex) => (
                        <div key={permissionIndex} className="permission-radio">
                          <input
                            type="radio"
                            {...register(`extension[${index}].permissions`, {
                              required: true,
                            })}
                            value={permission.value}
                          />
                          <label>
                            {permission.label}
                            <span style={{ fontSize: "7px", color: "gray" }}>
                              {permission.secondLabel}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <img
                  src={i18n.language === "nl" ? rightsDutch : rightsFrench}
                  alt="banner title"
                  className="custom-banner"
                />
                <button className="btn--full-add" onClick={addExtension}>
                  {t("form_add_users")}
                </button>
              </div>
            </Collapsible>
          )}
          <div className="form-section">
            <h3 className="label">{t("form_additional")}</h3>
            <br />
            <label className="label">
              {t("form_email_billing")}
              <span className="required-asterisk">*</span>
            </label>
            <input
              className="textbox"
              {...register("billingMail", {
                required: true,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
              })}
              placeholder={t("form_email_billing")}
            />
            {formState.errors.billingMail && (
              <p className="error-message">{t("form_field_required")}</p>
            )}
            <label className="label">
              {t("form_email_reminders")}
              <span className="required-asterisk">*</span>
            </label>
            <input
              className="textbox"
              {...register("reminderMail", {
                required: true,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
              })}
              placeholder={t("form_email_reminders")}
            />
            {formState.errors.reminderMail && (
              <p className="error-message">{t("form_field_required")}</p>
            )}
            <label className="label">
              {t("form_email_digital_receipt")}
              <span className="required-asterisk">*</span>
            </label>
            <input
              className="textbox"
              {...register("digitalReceiptMail", {
                required: true,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
              })}
              placeholder={t("form_email_digital_receipt")}
            />
            {formState.errors.digitalReceiptMail && (
              <p className="error-message">{t("form_field_required")}</p>
            )}
          </div>
          <div className="form-section">
            <input
              {...register(`termsAndConditions`, { required: true })}
              type="checkbox"
              className="disclaimer-checkbox"
            />
            <label className="disclaimerLabel">
              <a
                href="https://www.meconv.be/nl/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                {t("form_terms_and_conditions")}
              </a>
            </label>
            {formState.errors.termsAndConditions && (
              <span className="error-message2">
                {t("form_agreement_warning")}
              </span>
            )}
            <br />
            <input
              {...register(`newsletter`)}
              type="checkbox"
              className="disclaimer-checkbox"
            />
            <label className="disclaimerLabel">{t("form_newsletter")}</label>
          </div>
          <div className="form-section">
            <input
              className={`btn--full ${
                !formState.isValid ? "invalid-form" : ""
              }`}
              value={
                submissionStatus === "success"
                  ? t("form_request_success")
                  : t("form_send")
              }
              type="submit"
            />
          </div>
        </form>
      )}
    </div>
  );
}
