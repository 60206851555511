import React from "react";
import { useTranslation } from "react-i18next";

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-selector">
      <span onClick={() => changeLanguage("fr")}>FR</span>
      <br />
      <span onClick={() => changeLanguage("nl")}>NL</span>
    </div>
  );
}

export default LanguageSelector;
