import React from "react";
import { Link } from "react-router-dom";
import "./notFound.scss";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1>De link die u heeft gebruikt bestaat niet of is vervallen </h1>
      <h2>404 - Pagina kan niet worden gevonden.</h2>
      <Link to="/">Keer terug naar Homepagina</Link>
    </div>
  );
};

export default NotFound;
