import React from "react";
import { useTranslation } from "react-i18next";
import "./formSuccess.scss"; // Create a corresponding CSS file for styling if needed

const FormSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className="form-success">
      <h2>{t("form_success_title")}</h2>
      <p>{t("form_success_message")}</p>
    </div>
  );
};

export default FormSuccess;
