import Home from "./pages/home/Home";
import "./app.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./components/notfound/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/nl/:id" element={<Home />} />
        <Route path="/fr/:id" element={<Home />} />
        <Route path="/:lang/:id" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="not-found" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
